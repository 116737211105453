import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Icon } from '@common/atoms/Icon';
import { Logo } from '@common/atoms/Logo';
import { InternalLink, Route } from '@web/atoms/InternalLink';

import styles from './BettingDisclaimer.module.scss';

export type Color = 'light' | 'dark';

export enum LayoutOptions {
    SHOW_ALL = 'show-all',
    SETTINGS_ONLY = 'settings-only',
    DISCLAIMER_ONLY = 'disclaimer-only',
}

export enum LogoVariant {
    DEFAULT = 'default',
    TRANSPARENT = 'transparent',
}

interface Props {
    color?: Color;
    logoVariant?: LogoVariant;
    layoutOptions?: LayoutOptions;
    showEighteenPlusLogo?: boolean;
    className?: string;
}

export const BettingDisclaimer: FC<Props> = ({
    color = 'dark',
    logoVariant = LogoVariant.DEFAULT,
    layoutOptions = LayoutOptions.SHOW_ALL,
    className,
}) => {
    const __meta = useTranslation('meta').t;
    return (
        <div className={[styles.Disclaimer, styles[color], className].join(' ')}>
            {layoutOptions !== LayoutOptions.DISCLAIMER_ONLY ? (
                <InternalLink route={Route.Page} query={{ slug: __meta('url:play-responsibly') }}>
                    <a className={styles.settings} rel="nofollow" target="_blank">
                        <Icon.settings />
                        <div
                            className={`${styles['settings-text']} settings-text`}
                        >{__meta`label.hide-betting`}</div>
                    </a>
                </InternalLink>
            ) : null}
            {layoutOptions !== LayoutOptions.SETTINGS_ONLY ? (
                <InternalLink route={Route.Page} query={{ slug: __meta('url:play-responsibly') }}>
                    <a rel="nofollow" target="_blank">
                        {logoVariant === LogoVariant.DEFAULT ? (
                            <Logo.eighteenPlus />
                        ) : (
                            <Logo.eighteenPlusTransparentInline />
                        )}
                    </a>
                </InternalLink>
            ) : null}
        </div>
    );
};
